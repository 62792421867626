.navbar {
    width: 100%;
    background-color: #FF9200;
    z-index: 9999999;
    height: 80px;
}

.navbar > div {
    padding: 0px 40px;
}

.nav_logo > img {
    width: 185px;
    cursor: pointer;
}

.nav_icons {
    padding-bottom: 15px;
    margin-right: 16px;
    cursor: pointer;
}

.nav_icons > img {
    width: 36px;
}

.hamburger_menu {
    cursor: pointer;
}

.side_nav {
    top: 0;
    right: 0;
}

.profile_icon {
    width: 60px;
    height: 60px;
    background-color: #01BFD9;
    border-radius:  60px;
}

.profile_icon > img {
    width: 60px;
    height: 60px;
    border: 1px solid #fff;
    border-radius:  60px;
    cursor: pointer;
}

.profile_icon_dropdown {
    right: 0;
    width: 180px;
    padding: 6px;
    /* outline: 4px solid  rgba(123, 0, 131, .7);
    outline-offset: 3px; */
    border: 2px solid  #01BFD9;
}

.profile_icon_dropdown_icon {
    cursor: pointer;
    color: #555;
}

.profile_icon_dropdown_icon > svg {
    width: 20px;
    margin: 0 8px;
}

.dropdown_svg_icon {
    width: 20px;
    height: 20px;
    margin: 0 8px;
}

.nav_link{
     font-size: 20px;
     /* text-transform: uppercase; */
}

.active_nav {
    border-bottom: 5px solid #01BFD9;
    border-top: 1px solid #01BFD9;
    border-right: 1px solid #01BFD9;
    border-radius: 8px;
    border-left: 5px solid #01bfd9;
    padding: 4px 8px;
    color: #01BFD9;
}

.OxChams_Nav {
    height: 80px;
    padding-top: 15px;
    color: white;
    font-family: 'ChameleonCollective';

}

.dropdown_hr{
    width: 96%;
    height: 1px !important;
    color: #01BFD9;
}

@media (max-width: 768px) {
    .navbar > div {
        padding: 10px;
    }
}

@media (max-width: 420px) {
    .navbar > div {
        padding: 0;
    }
}
