@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.OxChams_minting_sec {
    background-color: #efefef;
}

.minting_type_text {
    font-family: 'Bebas';
    font-size: 24px;
    font-weight: 600;
    letter-spacing: .5px;
} 

.minting_type_sub_text {
    font-size: 16px;
    font-weight: 400;
}

.minting_bttn {
    width: 160px;
    font-family: 'ChameleonCollective';
    font-size: 26px;
    font-weight: 400;
    box-shadow: 3px 3px 1px 0px #9f9f9f;
    border-radius: 4px;
}

.minting_bttn__primary {
    background-color: #ffd600;
    color: #fff;
    cursor: pointer;
}

.minting_bttn__primary:hover {
    transform: scale(1.2);
}

.minting_bttn__secondary {
    background-color: #019a88;
    color: #fff;
    cursor: pointer;
}

.minting_bttn__secondary:hover {
    transform: scale(1.2);
}

.minting_bttn__tritary {
    background-color: #ff9201;
    color: #fff;
    cursor: pointer;
}

.minting_bttn__tritary:hover {
    transform: scale(1.2);
}

.psudo_line {
    color: #9f9f9f;
    font-size: 0;
}