@font-face {
    font-family: 'Bebas';
    src: url('../../assets/fonts/Bebas-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Bebas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.OxChams {
    width: 100%;
    min-height: 75vh;
    /* background-color: #fed32e; */
}

.OxChams_header {
    height: 120px;
    background-color: #333333;
}

.OxChams_header > h2 {
    font-size: 40px;
    font-family: 'ChameleonCollective';
    color: #ffd600;
}

.OxChams_header > h3 {
    font-size: 23px;
    font-family: 'Bebas';
    color: #fff;
}

.OxChams_text {
    font-size: 20px;
    font-weight: 500;
}

.OxChams_top_img {
    float: left;
    top: -40px;
    max-width: 600px;
}

.OxChams_text_semibold {
    font-weight: 600;
}

.founder_zach_img {
    width: 90%;
    max-width: 120px;
}

.founder_zach_img_sign {
    max-width: 180px;
}

.OxChams_img_three > img {
    width: 70%;
    right: 0;
}

@media (max-width: 600px) {
    .OxChams_img_three > img {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .founder_zach_img {
        max-width: 80px;
    }
    .founder_zach_img_sign {
        max-width: 120px;
    }
}