
.chamagotchi_details {
    min-height: 70vh;
}

.chamagotchi_details_img {
    box-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
    border-radius: 4px;
}

.custom_alert {
    width: 400px;
    position: absolute;
    top: 20px;
    left: calc(50% - 200px);
    background-color: #fff;
    border: 1px solid #01BFD9;
    border-radius: 4px;
    z-index: 1000000;
}