

.minting_sec {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 40px 40px 0 40px ;
    color: #000;
    font-size: 22px;
    font-weight: 500;
}

.mintCham > img {
    width: 100%;
}
.mint_content > p {
    text-align: left;
    padding-left: 32px;
}

.mint_content > ul > li {
    padding: 2px;
    text-align: left;
    padding-left: 24px;
    background-image: url("../../assets/Img//li_icon.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: 14px;
}

.mint_cham_btn{
    border: 2px solid #000;
    outline: none;
    background-color: #000;
    color: #fff;
    padding: 8px 40px;
    border-radius: 32px;
    font-weight: 600;
    top: 68%;
    left: 30%;
}

.mint_cham_btn:hover {
    background-color: #fff;
    color: #000;
}