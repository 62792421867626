@font-face {
    font-family: 'ChameleonCollective';
    src: url('../../assets/fonts/ChameleonCollective-Regular.otf'),
        url('../../assets/fonts/ChameleonCollective-Regular.ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.minting_popup_wrapper{
    min-width: 100vh;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000000;
}

.minting_popup {
    position: fixed;
    margin-top: 250px;
}

.design_pet_heading {
    font-family: 'ChameleonCollective';
    color: #e486a1;
    font-size: 80px;
}

.design_pet_text {
    font-size: 24px;
}

.create_pet_btn {
    background-image: url("../../assets/Img/purple-button.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 4px auto;
    padding: 12px;
    width: 250px;
    min-height: 64px;
    font-family: 'Bebas';     
    font-size: 24px;
    letter-spacing: .5px;
    color: #7595c6;
    font-weight: 500;
    cursor: pointer;
}

.create_pet_btn_defalult {
    background: #cbf7de;
    border: 5px solid #afe4c6;
    border-radius: 24px;
    box-shadow: 3px 5px 0 2px #e0e0e0;
    margin: 8px auto;
    padding: 8px 32px;
    width: max-content;
    font-family: 'Bebas';     
    font-size: 24px;
    letter-spacing: .5px;
    color: #7595c6;
    font-weight: 500;
    cursor: pointer;
}

.disabled_btn {
    opacity: .6;
    cursor: not-allowed;
}

.create_pet_Warn {
    font-weight: 500;
}

.pacman_loader {
    height: 50px;
}

.pacman_loader > div {
    top: 0;
    left: calc(50% - 35px);
}

@media (max-width: 768px) {
    .create_pet_btn {
        margin: 4px auto;
        padding: 8px 20px;
        width: 210px;
        min-height: 56px;
        font-size: 22px;
    }

    .create_pet_btn_defalult {
        padding: 8px 10px;
        font-size: 20px;
    }

    .design_pet_text {
        font-size: 18px;
    }
    
    .create_pet_Warn {
        font-size: 14px;
    }
}

@media ( max-width: 576px ) {
    .create_pet_btn {
        margin: 4px auto;
        padding: 6px 20px;
        width: 170px;
        min-height: 40px;
        font-size: 20px;
    }
}

@media ( max-width: 420px ) {
    .create_pet_btn {
        margin: 4px auto;
        padding: 3px 20px;
        width: 130px;
        min-height: 32px;
        font-size: 18px;
    }

    .create_pet_btn_defalult {
        padding: 4px 6px;
        font-size: 16px;
    }

    .design_pet_text {
        font-size: 16px;
    }

    .create_pet_Warn {
        font-size: 10px;
    }
}